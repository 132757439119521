export const ACADEMY_GROUP_MODAL_NAME = 'AcademyGroup'
export const ACADEMY_COURSE_MODAL_NAME = 'AcademyCourse'
export const ACADEMY_COURSE_REORDER_MODAL_NAME = 'AcademyCourseReorder'
export const ACADEMY_LESSON_MODAL_NAME = 'AcademyLesson'
export const ACADEMY_LESSON_REORDER_MODAL_NAME = 'AcademyLessonReorder'
export const ACADEMY_GROUP_REORDER_MODAL_NAME = 'AcademyGroupReorder'
export const DELETE_GROUP_MODAL_NAME = 'DeleteGroup'
export const LEAVE_CONFIRMATION_MODAL_NAME = 'AcademyAdminLeaveConfirmation'
export const DELETE_COURSE_MODAL_NAME = 'DeleteCourse'
export const DELETE_LESSON_MODAL_NAME = 'DeleteLesson'
export const INVALID_THUMBNAIL_ERROR = 'Thumbnails must start with https://'

export const ACADEMY_GROUP_FORM_FIELDS = [
  'title',
  'academy_content_type',
  'video_language',
  'description'
]

export const ACADEMY_COURSE_FORM_FIELDS = [
  'title',
  'description',
  'feature_image',
  'academy_resources',
  'coming_soon'
]

export const ACADEMY_LESSON_FORM_FIELDS = [
  'title',
  'description',
  'thumbnail_image',
  'wistia_id',
  'duration',
  'academy_resources'
]

export const LESSONS_COLUMNS = [
  { accessor: 'title', name: 'Lesson' },
  { accessor: 'description', name: 'Description' },
  { accessor: 'thumbnail_image', name: 'Thumbnail' },
  { accessor: 'wistia_id', name: 'Wistia ID' },
  { accessor: 'duration', name: 'Duration' },
  { accessor: 'lesson_actions' }
]
