export const LoadingStates = {
  initialLoad: 'initialLoad',
  loading: 'loading',
  loaded: 'loaded',
  failed: 'failed'
}

export const KEYWORD_LISTS_ENDPOINT = 'api/keyword_lists'

// Product Costs & Settings
export const PRODUCT_SETTINGS_PARENTS_ENDPOINT = `api/junglekit/product_costs_and_settings`
export const PRODUCT_SETTINGS_CHILDREN_ENDPOINT = `api/junglekit/product/children`
