import concat from 'lodash/concat'
import sortBy from 'lodash/sortBy'

import { PLAN_MEMBERSHIP_CODE_OPTIONS, PLAN_CODE_LABELS } from './shared'

export const BASE_PLANS_ICON_HEIGHT = '18px'

export const BASE_PLANS_TABLE_COLUMNS = [
  {
    displayName: 'ID',
    name: 'id',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Base Plan Name',
    name: 'unique_name',
    className: 'table__cell--header table__cell--large',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Billing Frequency',
    name: 'billing_frequency',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Price',
    name: 'price',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Activation Date',
    name: 'activation_date',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Expiration Date',
    name: 'expiration_date',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Status',
    name: 'status',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: '',
    name: 'actions',
    className: 'table__cell--header',
    isSortable: false
  }
]

export const BASE_PLAN_RENEWABLE_OPTIONS = [
  { name: 'Yes', value: true },
  { name: 'No', value: false }
]

export const BASE_PLAN_CODE_OPTIONS = sortBy(
  concat(
    PLAN_MEMBERSHIP_CODE_OPTIONS.map(o => {
      return { name: PLAN_CODE_LABELS[o], value: o }
    })
  ),
  ['name']
)

export const CHARGE_FREQUENCY_OPTIONS = [
  { name: '1 Month', value: 1 },
  { name: '2 Months', value: 2 },
  { name: '3 Months', value: 3 },
  { name: '4 Months', value: 4 },
  { name: '5 Months', value: 5 },
  { name: '6 Months', value: 6 },
  { name: '7 Months', value: 7 },
  { name: '8 Months', value: 8 },
  { name: '9 Months', value: 9 },
  { name: '10 Months', value: 10 },
  { name: '11 Months', value: 11 },
  { name: '12 Months', value: 12 }
]

export const BASE_PLAN_FORM_SECTIONS = {
  planInfo: 0,
  featureLimits: 1,
  checkout: 2
}

export const BASE_PLAN_LAST_STEP = 2

export const BASE_PLAN_FORM_MODES = {
  create: 'create',
  edit: 'edit'
}
