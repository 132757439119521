export const CAMPAIGNS_TABLE_COLUMNS = [
  {
    displayName: 'ID',
    name: 'id',
    className: 'table__cell--header table__cell--small',
    isSortable: true,
    defaultSort: 'desc'
  },
  {
    displayName: 'Campaign Name',
    name: 'unique_name',
    className: 'table__cell--header table__cell--large',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Price',
    name: 'price',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Discount %',
    name: 'percentage_off',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Activation Date',
    name: 'activation_date',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Expiration Date',
    name: 'expiration_date',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: 'Status',
    name: 'status',
    className: 'table__cell--header',
    isSortable: true,
    defaultSort: 'asc'
  },
  {
    displayName: '',
    name: 'actions',
    className: 'table__cell--header actions',
    isSortable: false
  }
]

export const CAMPAIGN_FORM_MODES = {
  create: 'create',
  edit: 'edit'
}

export const CAMPAIGN_FORM_SECTIONS = {
  basicInfo: {
    label: 'Campaign Info',
    stepNumber: 0
  },
  details: {
    label: 'Duration & Pricing',
    stepNumber: 1
  },
  planInfo: {
    label: 'Features',
    stepNumber: 2
  },
  checkout: {
    label: 'Checkout',
    stepNumber: 3
  },
  review: {
    label: 'Summary',
    stepNumber: 4
  }
}

export const LAST_CAMPAIGN_STEP = 4

export const CAMPAIGN_PRICE_ADJUSTMENT_OPTIONS = [
  { label: '$ - Campaign Price', value: 'amount' },
  { label: '% - Campaign Discount', value: 'percent' }
]

export const CAMPAIGN_ADJUSTMENT_LABELS = {
  amount: 'Campaign Price',
  percent: 'Campaign Discount'
}

export const CAMPAIGN_DURATION_OPTIONS = [
  { name: 'No Discount', value: null },
  { name: 'One-Time Coupon', value: 'once' },
  { name: 'Recurring Coupon', value: 'repeating' }
]

export const CAMPAIGN_BILLING_PERIODS_OPTIONS = [...Array(12).keys()]
  .map(x => x + 1)
  .map(x => ({ name: x, value: x }))
